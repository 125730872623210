import NextServer from './NextServer'

export default class LocalAPI extends NextServer { 

	async sendJWT(token) { 
		return await super.post('/auth', {token: token})
	}

	async getMe() { 
		return await super.get('/me')
	}

	async logout() { 
		return await super.get('/logout')
	}

	async getOpenGraphParse(url) {
		return await super.post(`/parse_ogs_links`, {url: url});
	}

	async storeGuideCookie(guideBody) { 
		return await super.post('/guide', guideBody);
	}
}