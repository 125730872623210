import { baseServerConfig } from '../store'

export default class MetricsManager {
    instanceData: any;
    amplitude: any;
    uniqueMap: {[key: string]: boolean }

    constructor() { 
    }

    initialize() { 
        this.amplitude = require('amplitude-js')
        this.amplitude.getInstance().init(process.env.amplitudeApiKey);
        this.instanceData = {};
    }

    updateCommonData(key: any, value: any) {
        if (!this.instanceData) { 
            this.instanceData = {};
        }
        this.instanceData[key] = value;
    }

    setCommonData(data: any) { 
        const isDict = dict => {
            return typeof dict === "object" && !Array.isArray(dict);
        };

        if (!isDict(data) || !data) { 
            return;
        }
        this.instanceData = data;
    }

    logEvent(event: string, data: any, callback: any = null) { 
        if (!this.amplitude) { 
            this.initialize();
        }
        this.amplitude.getInstance().logEvent(event, {...data, ...this.instanceData, ...{appDomain: baseServerConfig.get()}}, callback)
    }

    logEventOnce(event: string, data: any, key: string) { 
        if (!this.uniqueMap[key]) {
            this.logEvent(event, data);
        }
    }
}