
const UNIVERSE = 'rgba(28,35,43,.15)'

const constants = { 
REQUIRED_EVENT_SUBMIT_PROPERTIES: ["title", "address", "start_date", "end_date", "image_url", "external_url"],
headerTitle: "ETA: Discover hip-hop, afrobeat and black experiences",
headerDesc: "From the best hip hop clubs and day parties to art shows and black owned restaurants, we instantly help you find the dopest events happening for the culture 24/7",
centeredDiv: { 
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
},
movementNftLogoUrl: "https://www.movement.fm/static/movement_nft.jpg",
etaLogoUrl: "https://gameplan-image-urls.s3.us-east-2.amazonaws.com/eta_logo.jpg",
spacesLogoUrl: "https://gameplan-image-urls.s3.us-east-2.amazonaws.com/spaceslogo.png",
grayColor: '#6F7287',
lightGrayColor: '#f8f7fa',
beigeColor: '#f8f7fa',
boxShadow: `0px 0px 10px ${UNIVERSE}`,
spacesPrimaryColor: '#17a2b8',
spacesSecondaryColor: '#333333', 
etaPrimaryColor: '#00FFF0', 
etaSecondaryColor: 'black',
primaryETAButtonColor: '#17a2b8',
EtaButtonColorBright: '#2ac6df'
}

export default constants